import { useState, useMemo, useEffect } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardHeader,
  CardBody,
  Slider,
  Spinner,
} from '@nextui-org/react';
import { useMutation } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWandMagicSparkles,
  faUser,
  faPersonRunning,
  faDumbbell,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { CREATE_GPT } from '../graphql/mutations';
import {
  muscularGroupsTemplate,
  equipmentsTemplate,
  muscularGroupsTemplateEn,
  equipmentsTemplateEn,
} from '../utils/templates';
import { trackEvent } from '../utils/gt';

export const ModalCreationAI = ({
  isOpen,
  onOpenChange,
  onClose,
  setActiveTab,
  workoutData,
  setWorkoutData,
  setIsAIGenerated,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const muscularGroupsTemplateToUse =
    language === 'pt' ? muscularGroupsTemplate : muscularGroupsTemplateEn;
  const equipmentsTemplateToUse =
    language === 'pt' ? equipmentsTemplate : equipmentsTemplateEn;

  const [currentStep, setCurrentStep] = useState(0);
  const [trainingVariables, setTrainingVariables] = useState({
    muscularGroups: [t('components.ModalCreationAI.muscularGroupDefault')],
    intensity: 2,
    equipments: [t('components.ModalCreationAI.equipmentDefault')],
    exercisesRange: 5,
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });

  const [createGptMutation, { data: createGptData }] = useMutation(CREATE_GPT);

  useEffect(() => {
    if (createGptData) {
      try {
        const workoutsGptObj = JSON.parse(
          createGptData.createGpt.data.gptResponse
        );

        const sanitizedExercises = workoutsGptObj.exercises.map((exercise) => ({
          ...exercise,
          name: exercise.name
            .toLowerCase()
            .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase()),
          series: /^\d+$/.test(exercise.series)
            ? exercise.series
            : exercise.series.match(/\d+/)[0],
          reps: /^\d+$/.test(exercise.reps)
            ? exercise.reps
            : exercise.reps.match(/\d+/)[0],
        }));

        workoutsGptObj.exercises = sanitizedExercises;

        setIsAIGenerated(true);

        setWorkoutData({
          ...workoutData,
          ...workoutsGptObj,
        });

        setActiveTab('conclusion');
        setCurrentStep(5);
      } catch (error) {
        createGptMutation({
          variables: {
            gptInput: {
              gptMessageToSend: t('components.ModalCreationAI.gptMessage', {
                muscularGroups: trainingVariables.muscularGroups.join(', '),
                intensity: [
                  t('components.ModalCreationAI.light'),
                  t('components.ModalCreationAI.moderate'),
                  t('components.ModalCreationAI.high'),
                  t('components.ModalCreationAI.veryHigh'),
                ][trainingVariables.intensity - 1],
                equipments: trainingVariables.equipments.join(', '),
                exercisesRange: trainingVariables.exercisesRange,
              }),
            },
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGptData, setActiveTab, setWorkoutData]);

  const renderSteps = useMemo(() => {
    if (currentStep === 0) {
      return (
        <>
          <ModalHeader className="flex flex-col gap-1 text-white">
            {t('components.ModalCreationAI.newWorkout')}
          </ModalHeader>
          <ModalBody className="flex items-center justify-center">
            <div className="grid grid-cols-2 gap-4 text-white max-w-[500px] pb-2">
              <Card
                isPressable
                onPress={() => {
                  trackEvent(
                    'modal_creation_ai_manual',
                    'Modal Creation AI - Manual',
                    'modal_creation_ai',
                    true
                  );

                  setActiveTab('definitions');

                  setIsAIGenerated(false);

                  setWorkoutData({
                    ...workoutData,
                    description: '',
                    exercises: [
                      {
                        name: '',
                        series: '3',
                        reps: '12',
                        interval: 60,
                        howTo: '',
                      },
                    ],
                  });

                  onClose();
                }}
                className="col-span-2 sm:col-span-1 bg-orange-400"
              >
                <CardHeader className="justify-center">
                  <FontAwesomeIcon icon={faUser} size="3x" />
                </CardHeader>
                <CardBody>
                  <h4 className="text-white font-medium text-large text-center">
                    {t('components.ModalCreationAI.manually')}
                  </h4>
                </CardBody>
              </Card>
              <Card
                isPressable
                onPress={() => {
                  trackEvent(
                    'modal_creation_ai_artificial_intelligence',
                    'Modal Creation AI - Artificial Intelligence',
                    'modal_creation_ai',
                    true
                  );

                  setCurrentStep(1);
                }}
                className="col-span-2 sm:col-span-1 bg-sky-500"
              >
                <CardHeader className="justify-center">
                  <FontAwesomeIcon icon={faWandMagicSparkles} size="3x" />
                </CardHeader>
                <CardBody>
                  <h4 className="text-white font-medium text-large text-center">
                    {t('components.ModalCreationAI.artificialIntelligence')}
                  </h4>
                </CardBody>
              </Card>
            </div>
          </ModalBody>
        </>
      );
    }

    if (currentStep === 1) {
      return (
        <>
          <ModalHeader className="flex flex-col gap-1 text-white">
            {t('components.ModalCreationAI.muscularGroups')}
          </ModalHeader>
          <ModalBody>
            <div className="grid grid-cols-4 gap-4 text-white">
              {muscularGroupsTemplateToUse.map((muscularGroupTemplate) => (
                <Card
                  className={`col-span-2 sm:col-span-1 ${trainingVariables?.muscularGroups?.includes(muscularGroupTemplate) ? 'bg-green-500' : 'bg-zinc-600'}`}
                  key={muscularGroupTemplate}
                  isPressable
                  onPress={() => {
                    trackEvent(
                      `muscular_group_${muscularGroupTemplate}`,
                      `Muscular Group: ${muscularGroupTemplate}`,
                      'modal_creation_ai',
                      true
                    );

                    setTrainingVariables((prevVariables) => {
                      const { muscularGroups } = prevVariables;

                      if (
                        muscularGroupTemplate ===
                        t('components.ModalCreationAI.muscularGroupDefault')
                      ) {
                        return {
                          ...prevVariables,
                          muscularGroups: [
                            t(
                              'components.ModalCreationAI.muscularGroupDefault'
                            ),
                          ],
                        };
                      }

                      const index = muscularGroups.indexOf(
                        muscularGroupTemplate
                      );

                      if (index !== -1) {
                        const updatedMuscularGroups = [...muscularGroups];
                        updatedMuscularGroups.splice(index, 1);

                        return {
                          ...prevVariables,
                          muscularGroups: updatedMuscularGroups,
                        };
                      }

                      if (
                        muscularGroups.includes(
                          t('components.ModalCreationAI.muscularGroupDefault')
                        )
                      ) {
                        const index = muscularGroups.indexOf(
                          t('components.ModalCreationAI.muscularGroupDefault')
                        );

                        if (index !== -1) {
                          const updatedMuscularGroups = [...muscularGroups];
                          updatedMuscularGroups.splice(index, 1);

                          return {
                            ...prevVariables,
                            muscularGroups: [
                              ...updatedMuscularGroups,
                              muscularGroupTemplate,
                            ],
                          };
                        }
                      }

                      return {
                        ...prevVariables,
                        muscularGroups: [
                          ...muscularGroups,
                          muscularGroupTemplate,
                        ],
                      };
                    });
                  }}
                >
                  <CardHeader className="justify-center">
                    <FontAwesomeIcon icon={faPersonRunning} size="3x" />
                  </CardHeader>
                  <CardBody>
                    <h4 className="text-white font-medium text-large text-center">
                      {muscularGroupTemplate}
                    </h4>
                  </CardBody>
                </Card>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              onPress={() => {
                setCurrentStep(0);
              }}
            >
              {t('components.ModalCreationAI.back')}
            </Button>
            <Button
              className="text-white"
              color="primary"
              onPress={() => {
                setCurrentStep(2);
              }}
            >
              {t('components.ModalCreationAI.next')}
            </Button>
          </ModalFooter>
        </>
      );
    }

    if (currentStep === 2) {
      return (
        <>
          <ModalHeader className="flex flex-col gap-1 text-white">
            {t('components.ModalCreationAI.gear')}
          </ModalHeader>
          <ModalBody>
            <div className="grid grid-cols-4 gap-4 text-white">
              {equipmentsTemplateToUse.map((equipment) => (
                <Card
                  className={`col-span-4 sm:col-span-1 ${trainingVariables?.equipments?.includes(equipment) ? 'bg-green-500' : 'bg-zinc-600'}`}
                  key={equipment}
                  isPressable
                  onPress={() =>
                    setTrainingVariables((prevVariables) => {
                      trackEvent(
                        `equipment_${equipment}`,
                        `Equipment: ${equipment}`,
                        'modal_creation_ai',
                        true
                      );

                      const { equipments } = prevVariables;

                      if (
                        equipment ===
                        t('components.ModalCreationAI.equipmentDefault')
                      ) {
                        return {
                          ...prevVariables,
                          equipments: [
                            t('components.ModalCreationAI.equipmentDefault'),
                          ],
                        };
                      }

                      const index = equipments.indexOf(equipment);

                      if (index !== -1) {
                        const updatedEquipments = [...equipments];
                        updatedEquipments.splice(index, 1);

                        return {
                          ...prevVariables,
                          equipments: updatedEquipments,
                        };
                      }

                      if (
                        equipments.includes(
                          t('components.ModalCreationAI.equipmentDefault')
                        )
                      ) {
                        const index = equipments.indexOf(
                          t('components.ModalCreationAI.equipmentDefault')
                        );

                        if (index !== -1) {
                          const updatedEquipments = [...equipments];
                          updatedEquipments.splice(index, 1);

                          return {
                            ...prevVariables,
                            equipments: [...updatedEquipments, equipment],
                          };
                        }
                      }

                      return {
                        ...prevVariables,
                        equipments: [...equipments, equipment],
                      };
                    })
                  }
                >
                  <CardHeader className="justify-center">
                    <FontAwesomeIcon icon={faDumbbell} size="2x" />
                  </CardHeader>
                  <CardBody>
                    <h4 className="text-white font-medium text-sm text-center">
                      {equipment}
                    </h4>
                  </CardBody>
                </Card>
              ))}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              onPress={() => {
                setCurrentStep(1);
              }}
            >
              {t('components.ModalCreationAI.back')}
            </Button>
            <Button
              className="text-white"
              color="primary"
              onPress={() => {
                setCurrentStep(3);
              }}
            >
              {t('components.ModalCreationAI.next')}
            </Button>
          </ModalFooter>
        </>
      );
    }

    if (currentStep === 3) {
      return (
        <>
          <ModalHeader className="flex flex-col gap-1 text-white">
            {t('components.ModalCreationAI.workoutIntensity')}
          </ModalHeader>
          <ModalBody>
            <div className="grid grid-cols-1 gap-4 text-white">
              <Slider
                label={t('components.ModalCreationAI.intensity')}
                color="danger"
                step={1}
                maxValue={4}
                minValue={1}
                className="col-span-1"
                value={trainingVariables?.intensity}
                onChange={(intensity) => {
                  setTrainingVariables({
                    ...trainingVariables,
                    intensity,
                  });
                }}
                hideValue
                marks={[
                  {
                    value: 1,
                    label: t('components.ModalCreationAI.light'),
                  },
                  {
                    value: 2,
                    label: t('components.ModalCreationAI.moderate'),
                  },
                  {
                    value: 3,
                    label: t('components.ModalCreationAI.high'),
                  },
                  {
                    value: 4,
                    label: t('components.ModalCreationAI.veryHigh'),
                  },
                ]}
              />
              <Slider
                label={t('components.ModalCreationAI.exerciseQuantity')}
                color="secondary"
                step={1}
                maxValue={10}
                minValue={1}
                hideValue
                className="col-span-1 mt-8"
                value={trainingVariables?.exercisesRange}
                onChange={(exercisesRange) => {
                  setTrainingVariables({
                    ...trainingVariables,
                    exercisesRange,
                  });
                }}
                marks={[
                  {
                    value: 1,
                    label: '1',
                  },
                  {
                    value: 2,
                    label: '2',
                  },
                  {
                    value: 3,
                    label: '3',
                  },
                  {
                    value: 4,
                    label: '4',
                  },
                  {
                    value: 5,
                    label: '5',
                  },
                  {
                    value: 6,
                    label: '6',
                  },
                  {
                    value: 7,
                    label: '7',
                  },
                  {
                    value: 8,
                    label: '8',
                  },
                  {
                    value: 9,
                    label: '9',
                  },
                  {
                    value: 10,
                    label: '10',
                  },
                ]}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              onPress={() => {
                setCurrentStep(2);
              }}
            >
              {t('components.ModalCreationAI.back')}
            </Button>
            <Button
              className="text-white"
              color="primary"
              onPress={() => {
                createGptMutation({
                  variables: {
                    gptInput: {
                      gptMessageToSend: t(
                        'components.ModalCreationAI.gptMessage',
                        {
                          muscularGroups:
                            trainingVariables.muscularGroups.join(', '),
                          intensity: [
                            t('components.ModalCreationAI.light'),
                            t('components.ModalCreationAI.moderate'),
                            t('components.ModalCreationAI.high'),
                            t('components.ModalCreationAI.veryHigh'),
                          ][trainingVariables.intensity - 1],
                          equipments: trainingVariables.equipments.join(', '),
                          exercisesRange: trainingVariables.exercisesRange,
                        }
                      ),
                    },
                  },
                });

                setCurrentStep(4);
              }}
            >
              {t('components.ModalCreationAI.generateWorkout')}
            </Button>
          </ModalFooter>
        </>
      );
    }

    if (currentStep === 4) {
      return (
        <>
          <ModalBody>
            <div className="grid grid-cols-1 gap-4 text-white min-h-[400px]">
              <Spinner
                label={t('components.ModalCreationAI.waitMeanwhile')}
                color="success"
              />
            </div>
          </ModalBody>
        </>
      );
    }

    if (currentStep === 5) {
      return (
        <>
          <ModalBody>
            <div className="flex items-center justify-center flex-col gap-4 text-white min-h-[400px]">
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="3x"
                className="text-primary"
              />
              <h1 className="text-4xl">
                {t('components.ModalCreationAI.workoutGeneratedSuccess')}
              </h1>
              <p className="mt-2">
                {t('components.ModalCreationAI.reviewOrAlter')}
              </p>
              <Button
                className="text-white mt-8"
                color="primary"
                onPress={() => {
                  setCurrentStep(1);
                  onClose();
                }}
              >
                {t('components.ModalCreationAI.reviewWorkout')}
              </Button>
            </div>
          </ModalBody>
        </>
      );
    }
  }, [
    createGptMutation,
    currentStep,
    equipmentsTemplateToUse,
    muscularGroupsTemplateToUse,
    onClose,
    setActiveTab,
    setIsAIGenerated,
    setWorkoutData,
    t,
    trainingVariables,
    workoutData,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      backdrop="blur"
      size={isMobile ? 'sm' : '2xl'}
      hideCloseButton
      isDismissable={false}
    >
      <ModalContent>{(onClose) => <>{renderSteps}</>}</ModalContent>
    </Modal>
  );
};
