import {
  Card,
  CardHeader,
  CardFooter,
  Image,
  Button,
  Link,
} from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

import homeBanner from '../assets/homeBanner.jpg';
import meuTreinoLogo from '../assets/meuTreinoLogo.png';

export const Banner = () => {
  const { t } = useTranslation();

  return (
    <Card
      isFooterBlurred
      className="w-6/6 h-[400px] sm:h-[600px] col-span-12sm:col-span-7"
    >
      <CardHeader className="absolute z-10 top-1 flex-col items-start">
        <p className="text-tiny text-white/60 uppercase font-bold">
          {t('components.Banner.meetPersonal')}
        </p>
        <h4 className="text-white/90 font-medium text-xl">
          {t('components.Banner.workoutAtHome')}
        </h4>
      </CardHeader>
      <Image
        removeWrapper
        alt="Relaxing app background"
        className="z-0 w-full h-full object-cover"
        src={homeBanner}
      />
      <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
        <div className="flex flex-grow gap-2 items-center">
          <Image
            alt="Meu treino icon"
            className="rounded-full w-10"
            src={meuTreinoLogo}
          />
          <div className="flex flex-col">
            <p className="text-tiny text-white/60">{t('appName')}</p>
            <p className="text-tiny text-white/60 hidden sm:block">
              {t('components.Banner.newWay')}
            </p>
          </div>
        </div>
        <Button
          href={t('alexaUrl')}
          as={Link}
          showAnchorIcon
          isExternal
          radius="full"
          size="sm"
        >
          {t('components.Banner.activate')}
        </Button>
      </CardFooter>
    </Card>
  );
};
