import {
  Card,
  CardHeader,
  CardFooter,
  Image,
  Button,
  useDisclosure,
} from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

import { trackEvent } from '../utils/gt';

import { ModalSignup } from './ModalSignup';
import { ModalLogin } from './ModalLogin';

import signupImg from '../assets/signupImg.jpg';

export const SignupBanner = () => {
  const { t } = useTranslation();

  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onOpenChange: onLoginOpenChange,
    onClose: onLoginClose,
  } = useDisclosure();

  const {
    isOpen: isSignupOpen,
    onOpen: onSignupOpen,
    onOpenChange: onSignupOpenChange,
    onClose: onSignupClose,
  } = useDisclosure();

  return (
    <>
      <Card className="w-6/6 h-[300px] col-span-12 sm:col-span-7 mt-10 md:mt-12">
        <CardHeader className="absolute z-10 top-1 flex-col items-start">
          <p className="text-tiny text-white/60 uppercase font-bold">
            {t('components.SignupBanner.newWay')}
          </p>
          <h4 className="text-white/90 font-medium text-xl">
            {t('components.SignupBanner.createAcct')}
          </h4>
        </CardHeader>
        <Image
          removeWrapper
          alt="Relaxing app background"
          className="z-0 w-full h-full object-cover"
          src={signupImg}
        />
        <CardFooter className="absolute bottom-0 z-10 flex justify-end">
          <Button
            color="primary"
            onPress={() => {
              trackEvent(
                'signup_banner_signup',
                'Signup Banner - Click Signup',
                'signup_banner',
                true
              );

              onSignupOpen();
            }}
          >
            {t('forms.buttons.signUp')}
          </Button>
        </CardFooter>
      </Card>

      <ModalLogin
        isOpen={isLoginOpen}
        onOpenChange={onLoginOpenChange}
        onClose={onLoginClose}
      />
      <ModalSignup
        isOpen={isSignupOpen}
        onOpenChange={onSignupOpenChange}
        onClose={onSignupClose}
        openLogin={onLoginOpen}
      />
    </>
  );
};
