import { Card, CardHeader, Image, Spacer } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

import usingPhone from '../assets/usingPhone.jpg';
import alexa from '../assets/alexa.jpg';

export const CardSequenceUsage = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center mt-4 sm:mt-10">
      <Card className="col-span-12 sm:col-span-4 h-[300px]">
        <CardHeader className="absolute z-10 bottom-1 flex-col !items-start">
          <div className="backdrop-blur rounded p-1">
            <p className="text-tiny text-white/60 uppercase font-bold">
              {t('components.CardSequenceUsage.mountWorkouts')}
            </p>
            <h4 className="text-white font-medium text-large">
              {t('components.CardSequenceUsage.withAI')}
            </h4>
          </div>
        </CardHeader>
        <Image
          removeWrapper
          alt="Card background"
          className="z-0 w-full h-full object-cover"
          src={usingPhone}
        />
      </Card>
      <Spacer x={4} />
      <Card className="col-span-12 sm:col-span-4 h-[300px]">
        <CardHeader className="absolute z-10 top-1 flex-col !items-start">
          <div className="backdrop-blur rounded p-1">
            <p className="text-tiny text-white/60 uppercase font-bold">
              {t('standalone.alexaStart')}
            </p>
            <h4 className="text-white font-medium text-large">
              {t('appName')}
            </h4>
          </div>
        </CardHeader>
        <Image
          removeWrapper
          alt="Card background"
          className="z-0 w-full h-full object-cover"
          src={alexa}
        />
      </Card>
    </div>
  );
};
