import { useEffect, useState } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Checkbox,
} from '@nextui-org/react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignature,
  faAt,
  faLock,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';
import { useTranslation } from 'react-i18next';

import { REGISTER } from '../graphql/mutations';
import { trackEvent } from '../utils/gt';

import { Notification } from './Notification';

export const ModalSignup = ({ isOpen, onOpenChange, onClose, openLogin }) => {
  const { t } = useTranslation();

  const schema = yup.object({
    name: yup.string().required(t('forms.validations.name')),
    username: yup.string().required(t('forms.validations.username')),
    email: yup
      .string()
      .email(t('forms.validations.emailSignup'))
      .required(t('forms.validations.emailSignupReq')),
    password: yup
      .string()
      .min(6, t('forms.validations.pwdSignup'))
      .required(t('forms.validations.pwdReq')),
    captcha: yup.string().required(t('forms.validations.captcha')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [captchaError, setCaptchaError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { redirectUrl } = useParams();

  const { search } = location;

  const [
    signUpMutation,
    { data: signUpData, loading: isLoadingSignup, error: signupError },
  ] = useMutation(REGISTER);

  useEffect(() => {
    isOpen &&
      trackEvent(
        'modal_signup_open',
        'Modal Signup Open',
        'modal_signup',
        true
      );
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      loadCaptchaEnginge(6, 'black', 'white');
    }
  }, [isOpen]);

  useEffect(() => {
    if (signupError) {
      const { message } = signupError;

      if (/already taken/.test(message)) {
        trackEvent(
          'modal_signup_user_taken',
          'Modal Signup User Taken',
          'modal_signup',
          true
        );

        toast.error(t('notifications.userTaken'));
      } else {
        trackEvent(
          'modal_signup_general_error',
          `Modal Signup General Error - ${message}`,
          'modal_signup',
          true
        );

        toast.error(t('notifications.signupError'));
      }
    }
  }, [signupError, t]);

  useEffect(() => {
    if (signUpData) {
      const { jwt } = signUpData.register;

      localStorage.setItem('authToken', jwt);

      toast(t('notifications.signupSuccess'));

      navigate('/dashboard?w=true');
      onClose();
    }
  }, [navigate, onClose, redirectUrl, search, signUpData, t]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      backdrop="blur"
    >
      <Notification />
      <ModalContent>
        {(onClose) => (
          <>
            <form
              onSubmit={handleSubmit((data) => {
                if (validateCaptcha(data.captcha) === false) {
                  setCaptchaError(t('notifications.invalidCaptcha'));

                  toast.error(t('notifications.invalidCaptcha'));
                  return;
                }

                if (
                  // eslint-disable-next-line no-restricted-globals
                  !confirm(
                    t('forms.other.confirmEmailSameAmazon', {
                      email: data.email,
                    })
                  )
                ) {
                  return false;
                }

                trackEvent(
                  'modal_signup_submit',
                  'Modal Signup Submit',
                  'modal_signup',
                  true
                );

                signUpMutation({
                  variables: {
                    input: {
                      name: data.name,
                      username: data.username,
                      email: data.email,
                      password: data.password,
                      origin: window.origin,
                    },
                  },
                });
              })}
            >
              <ModalHeader className="flex flex-col gap-1 text-white">
                {t('components.ModalSignup.createAcct')}
              </ModalHeader>
              <ModalBody>
                <div className="text-green-400 self-center mb-2">
                  {t('components.ModalSignup.alreadyRegisteredQuestion')}{' '}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => {
                      onClose();
                      openLogin();
                    }}
                  >
                    {t('forms.buttons.login')}
                  </span>
                </div>
                <Input
                  {...register('name')}
                  errorMessage={errors?.name?.message}
                  isInvalid={!!errors?.name?.message}
                  startContent={<FontAwesomeIcon icon={faSignature} inverse />}
                  label={t('forms.labels.name')}
                  variant="bordered"
                  className="text-white"
                />
                <Input
                  {...register('username')}
                  errorMessage={errors?.username?.message}
                  isInvalid={!!errors?.username?.message}
                  startContent={<FontAwesomeIcon icon={faAt} inverse />}
                  label={t('forms.labels.username')}
                  variant="bordered"
                  className="text-white"
                />
                <Input
                  {...register('email')}
                  errorMessage={errors?.email?.message}
                  isInvalid={!!errors?.email?.message}
                  endContent={<FontAwesomeIcon icon={faEnvelope} inverse />}
                  label={t('forms.labels.emailSignup')}
                  variant="bordered"
                  className="text-white"
                />
                <Input
                  {...register('password')}
                  errorMessage={errors?.password?.message}
                  isInvalid={!!errors?.password?.message}
                  endContent={<FontAwesomeIcon icon={faLock} inverse />}
                  label={t('forms.labels.pwd')}
                  type="password"
                  variant="bordered"
                  className="text-white"
                  description={t('forms.labels.pwdDescription')}
                />
                <Checkbox color="success" isRequired>
                  {t('standalone.agreeTerms')}{' '}
                  <a href={t('termsUrl')} target="_blank">
                    {t('standalone.agreeTermsLinkText')}
                  </a>
                </Checkbox>
                <div className="flex items-baseline justify-around mt-4">
                  <LoadCanvasTemplateNoReload />
                  <Input
                    {...register('captcha')}
                    errorMessage={errors?.captcha?.message || captchaError}
                    isInvalid={!!errors?.captcha?.message || !!captchaError}
                    label={t('forms.labels.captcha')}
                    variant="bordered"
                    className="text-white w-6/12 mt-3"
                    onFocus={() => setCaptchaError(false)}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  type="submit"
                  isLoading={isLoadingSignup}
                  isDisabled={isLoadingSignup}
                >
                  {t('forms.buttons.signUp')}
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
