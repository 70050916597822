import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-20 mb-10 text-center">
      <p className="text-tiny text-white/60 uppercase font-bold flex flex-col">
        <a href={t('termsUrl')} target="_blank" className="text-white/50  mb-4">
          {t('standalone.agreeTermsLinkText')}
        </a>
        {t('components.Footer.contactUs')}{' '}
        <a
          href={`mailto:${t('companyMail')}`}
          target="_blank"
          className="text-white/50"
        >
          {t('companyMail')}
        </a>
      </p>
    </div>
  );
};
