import {
  Card,
  CardBody,
  Input,
  Select,
  SelectItem,
  Slider,
  Textarea,
} from '@nextui-org/react';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export const ExerciseCard = ({ index, workoutData, setWorkoutData }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 640px)',
  });
  const { t } = useTranslation();

  const seriesSelection = Array.from({ length: 10 }, (_, i) => String(i + 1));
  const repsSelection = Array.from({ length: 50 }, (_, i) => String(i + 1));

  return (
    <Card className="col-span-1">
      <CardBody>
        <div className="grid grid-cols-12 gap-6 items-center">
          <div className="col-span-12 sm:col-span-4">
            <Input
              isRequired
              value={workoutData?.exercises[index]?.name}
              onValueChange={(name) => {
                const updatedExercises = [...workoutData.exercises];

                updatedExercises[index] = {
                  ...updatedExercises[index], // Preserve other properties
                  name: name
                    .toLowerCase()
                    .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase()),
                };

                setWorkoutData({
                  ...workoutData,
                  exercises: updatedExercises,
                });
              }}
              startContent={<FontAwesomeIcon icon={faDumbbell} inverse />}
              label={t('components.ExerciseCard.exercise')}
              placeholder={t('components.ExerciseCard.exerciseEx')}
              variant="bordered"
              className="text-white"
              autoFocus={!isMobile}
            />
          </div>
          <div className="col-span-12 sm:col-span-2">
            <Select
              selectedKeys={[workoutData?.exercises[index]?.series]}
              onChange={(e) => {
                if (e.target.value) {
                  const updatedExercises = [...workoutData.exercises];

                  updatedExercises[index] = {
                    ...updatedExercises[index],
                    series: e.target.value,
                  };

                  setWorkoutData({
                    ...workoutData,
                    exercises: updatedExercises,
                  });
                }
              }}
              label={t('components.ExerciseCard.series')}
              size="lg"
              className="text-white"
            >
              {seriesSelection.map((seriesSelectionNumber) => (
                <SelectItem
                  key={seriesSelectionNumber}
                  value={seriesSelectionNumber}
                  textValue={seriesSelectionNumber}
                >
                  <p className="text-white text-2xl">{seriesSelectionNumber}</p>
                </SelectItem>
              ))}
            </Select>
          </div>

          <div className="col-span-12 sm:col-span-2">
            <Select
              selectedKeys={[workoutData?.exercises[index]?.reps]}
              onChange={(e) => {
                if (e.target.value) {
                  const updatedExercises = [...workoutData.exercises];

                  updatedExercises[index] = {
                    ...updatedExercises[index],
                    reps: e.target.value,
                  };

                  setWorkoutData({
                    ...workoutData,
                    exercises: updatedExercises,
                  });
                }
              }}
              label={t('components.ExerciseCard.reps')}
              size="lg"
              className="text-white"
            >
              {repsSelection.map((repsSelectionNumber) => (
                <SelectItem
                  key={repsSelectionNumber}
                  value={repsSelectionNumber}
                  textValue={repsSelectionNumber}
                >
                  <p className="text-white text-2xl">{repsSelectionNumber}</p>
                </SelectItem>
              ))}
            </Select>
          </div>

          <div className="col-span-12 sm:col-span-3">
            <Slider
              label={t('components.ExerciseCard.seriesInterval')}
              step={10}
              maxValue={300}
              minValue={10}
              value={workoutData?.exercises[index]?.interval}
              onChange={(interval) => {
                const updatedExercises = [...workoutData.exercises];

                updatedExercises[index] = {
                  ...updatedExercises[index],
                  interval,
                };

                setWorkoutData({
                  ...workoutData,
                  exercises: updatedExercises,
                });
              }}
              getValue={(seconds) =>
                `${String(Math.floor(seconds / 60)).padStart(1, '0')}:${String(seconds % 60).padStart(2, '0')}`
              }
            />
          </div>
          <div className="col-span-12 sm:col-span-1 text-center">
            {index !== 0 && (
              <FontAwesomeIcon
                icon={faTrash}
                inverse
                className="cursor-pointer"
                onClick={() => {
                  const updatedExercises = [...workoutData.exercises];

                  updatedExercises.splice(index, 1);

                  setWorkoutData({
                    ...workoutData,
                    exercises: updatedExercises,
                  });
                }}
              />
            )}
          </div>
        </div>
        <Textarea
          label={t('components.ExerciseCard.executionLabel')}
          placeholder={t('components.ExerciseCard.executionPlaceholder')}
          className="mt-3"
          value={workoutData?.exercises[index]?.howTo}
          onValueChange={(howTo) => {
            const updatedExercises = [...workoutData.exercises];

            updatedExercises[index] = {
              ...updatedExercises[index],
              howTo: `${howTo.charAt(0).toUpperCase()}${howTo.slice(1)}`,
            };

            setWorkoutData({
              ...workoutData,
              exercises: updatedExercises,
            });
          }}
        />
      </CardBody>
    </Card>
  );
};
