import { useEffect } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from '@nextui-org/react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { LOGIN } from '../graphql/mutations';
import { trackEvent } from '../utils/gt';

import { Notification } from './Notification';

export const ModalLogin = ({ isOpen, onOpenChange, onClose }) => {
  const { t } = useTranslation();

  const schema = yup.object({
    identifier: yup
      .string()
      .email(t('forms.validations.email'))
      .required(t('forms.validations.emailReq')),
    password: yup
      .string()
      .min(6, t('forms.validations.pwd'))
      .required(t('forms.validations.pwdReq')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const location = useLocation();
  const { redirectUrl } = useParams();

  const { pathname, search } = location;

  const [
    loginMutation,
    { data: loginData, loading: isLoadingLogin, error: loginError },
  ] = useMutation(LOGIN);

  useEffect(() => {
    isOpen &&
      trackEvent('modal_login_open', 'Modal Login Open', 'modal_login', true);
  }, [isOpen]);

  useEffect(() => {
    if (/^\/login/.test(pathname)) {
      setTimeout(() => {
        toast.warn(t('notifications.loginExpired'), {
          toastId: 'login-expired',
        });

        trackEvent(
          'modal_login_expired',
          'Modal Login Expired',
          'modal_login',
          true
        );
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loginError) {
      toast.error(t('notifications.invalidUsername'));

      trackEvent(
        'modal_login_wrong_pwd',
        'Modal Login Wrong Password',
        'modal_login',
        true
      );
    }
  }, [loginError, t]);

  useEffect(() => {
    if (loginData) {
      const { jwt } = loginData.login;

      localStorage.setItem('authToken', jwt);

      if (redirectUrl) {
        navigate(`/${redirectUrl}${search}`);
        onClose();
      } else {
        navigate('/dashboard');
        onClose();
      }
    }
  }, [loginData, navigate, onClose, redirectUrl, search]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      backdrop="blur"
    >
      <Notification />
      <ModalContent>
        {(onClose) => (
          <>
            <form
              onSubmit={handleSubmit((data) => {
                trackEvent(
                  'modal_login_submit',
                  'Modal Login Submit',
                  'modal_login',
                  true
                );

                loginMutation({
                  variables: {
                    input: {
                      ...data,
                      provider: 'local',
                    },
                  },
                });
              })}
            >
              <ModalHeader className="flex flex-col gap-1 text-white">
                {t('forms.buttons.login')}
              </ModalHeader>
              <ModalBody>
                <Input
                  {...register('identifier')}
                  errorMessage={errors?.identifier?.message}
                  isInvalid={!!errors?.identifier?.message}
                  autoFocus
                  endContent={<FontAwesomeIcon icon={faEnvelope} inverse />}
                  label={t('forms.labels.email')}
                  variant="bordered"
                  className="text-white"
                />
                <Input
                  {...register('password')}
                  errorMessage={errors?.password?.message}
                  isInvalid={!!errors?.password?.message}
                  endContent={<FontAwesomeIcon icon={faLock} inverse />}
                  label={t('forms.labels.pwd')}
                  type="password"
                  variant="bordered"
                  className="text-white"
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  type="submit"
                  isLoading={isLoadingLogin}
                  isDisabled={isLoadingLogin}
                >
                  {t('forms.buttons.login')}
                </Button>
              </ModalFooter>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
