import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Card,
  CardHeader,
  CardFooter,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
} from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { DELETE_WORKOUT } from '../graphql/mutations';
import { workoutImages } from '../utils/workoutImages';

export const WorkoutCard = ({
  id,
  name,
  description,
  image,
  refetchWorkouts,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  const [removeWorkoutMutation] = useMutation(DELETE_WORKOUT);

  return (
    <Card className="col-span-2 sm:col-span-1 cursor-pointer" isPressable>
      <CardHeader className="absolute z-10 top-1 flex-col !items-end">
        <Popover
          isOpen={isPopoverOpen}
          onOpenChange={(open) => setPopoverOpen(open)}
        >
          <PopoverTrigger>
            <div className="backdrop-blur-md rounded p-1">
              <FontAwesomeIcon icon={faTrash} inverse />
            </div>
          </PopoverTrigger>
          <PopoverContent>
            <div className="px-1 py-2 text-white/80 grid grid-col-2">
              <div className="text-small font-bold col-span-2">
                {t('components.WorkoutCard.removeWorkoutQ')} {name}?
              </div>
              <Button
                color="danger"
                variant="bordered"
                onPress={async () => {
                  await removeWorkoutMutation({
                    variables: {
                      workoutId: id,
                    },
                  });

                  refetchWorkouts();

                  setPopoverOpen(false);
                }}
                className="col-span-1 col-start-2 mt-4 w-4"
              >
                {t('components.WorkoutCard.remove')}
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      </CardHeader>
      <Image
        isZoomed
        removeWrapper
        alt="Card background"
        className="z-0 w-full h-[300px] object-cover"
        src={workoutImages.find((img) => img.includes(image))}
        onClick={() => navigate(`/workout/${id}`)}
      />
      <CardFooter className="absolute bottom-0 z-10 grid grid-cols-1 text-left">
        <p className="text-lg text-white/80 uppercase font-bold col-span-1">
          {t('standalone.workout')} {name}
        </p>
        {description && (
          <p className="text-sm text-white/80 uppercase font-bold col-span-1">
            {description}
          </p>
        )}
      </CardFooter>
    </Card>
  );
};
