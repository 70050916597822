import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardHeader,
  Image,
} from '@nextui-org/react';
import { useTranslation } from 'react-i18next';

import usingPhone2 from '../assets/usingPhone2.jpg';
import speakToAlexa from '../assets/speakToAlexa.jpg';

export const ModalWelcome = ({ closeWelcome }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen
      placement="top-center"
      backdrop="blur"
      hideCloseButton
      size="xl"
      className="bg-transparent shadow-none"
      onClick={closeWelcome}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-white">
              {t('components.ModalWelcome.welcomeHeader')}
            </ModalHeader>
            <ModalBody className="text-white">
              <div className="grid grid-cols-2 gap-2">
                <Card className="col-span-4 sm:col-span-1">
                  <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                    <div className="backdrop-blur rounded p-1">
                      <p className="text-sm text-white/60 uppercase font-bold">
                        {t('components.ModalWelcome.mountWorkouts')}
                      </p>
                    </div>
                  </CardHeader>
                  <Image
                    removeWrapper
                    alt="Card background"
                    className="z-0 w-full h-full object-cover"
                    src={usingPhone2}
                  />
                </Card>
                <Card className="col-span-4 sm:col-span-1">
                  <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                    <div className="backdrop-blur-md rounded p-1">
                      <p className="text-sm text-white/60 uppercase font-bold">
                        {t('components.ModalWelcome.askAlexa')}
                      </p>
                    </div>
                  </CardHeader>
                  <Image
                    removeWrapper
                    alt="Card background"
                    className="z-0 w-full h-full object-cover"
                    src={speakToAlexa}
                  />
                </Card>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                onPress={closeWelcome}
                color="primary"
                className="text-white"
              >
                {t('components.ModalWelcome.getStarted')}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
