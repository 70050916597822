import { useTranslation } from 'react-i18next';

export const Disclaimer = () => {
  const { t } = useTranslation();

  return (
    <div className="w-6/6 mt-10 md:mt-12">
      <p className="text-tiny text-white/60 uppercase font-bold">
        {t('components.Disclaimer.copy')}
      </p>
    </div>
  );
};
