import { Card, CardHeader, CardBody } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faWallet,
  faClock,
  faUserLock,
  faMicrochip,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export const CardSequenceBenefits = () => {
  const { t } = useTranslation();

  return (
    <div className="grid grid-cols-2 gap-4 mt-4 mx-0 sm:mt-10 xl:mx-40 md:grid-cols-5">
      <Card className="col-span-4 sm:col-span-1 bg-amber-500">
        <CardHeader className="justify-center">
          <FontAwesomeIcon icon={faHouse} size="3x" />
        </CardHeader>
        <CardBody>
          <div className="backdrop-blur rounded p-1">
            <p className="text-tiny text-white/60 uppercase font-bold text-center">
              {t('components.CardSequenceBenefits.card1')}
            </p>
            <h4 className="text-white font-medium text-large text-center">
              {t('components.CardSequenceBenefits.card1Sub')}
            </h4>
          </div>
        </CardBody>
      </Card>
      <Card className="col-span-4 sm:col-span-1 bg-emerald-500">
        <CardHeader className="justify-center">
          <FontAwesomeIcon icon={faWallet} size="3x" />
        </CardHeader>
        <CardBody>
          <div className="backdrop-blur rounded p-1">
            <p className="text-tiny text-white/60 uppercase font-bold text-center">
              {t('components.CardSequenceBenefits.card2')}
            </p>
            <h4 className="text-white font-small text-large text-center">
              {t('components.CardSequenceBenefits.card2Sub')}
            </h4>
          </div>
        </CardBody>
      </Card>
      <Card className="col-span-4 sm:col-span-1 bg-fuchsia-500">
        <CardHeader className="justify-center">
          <FontAwesomeIcon icon={faClock} size="3x" />
        </CardHeader>
        <CardBody>
          <div className="backdrop-blur rounded p-1">
            <p className="text-tiny text-white/60 uppercase font-bold text-center">
              {t('components.CardSequenceBenefits.card3')}
            </p>
            <h4 className="text-white font-medium text-large text-center">
              {t('components.CardSequenceBenefits.card3Sub')}
            </h4>
          </div>
        </CardBody>
      </Card>
      <Card className="col-span-4 sm:col-span-1 bg-rose-500">
        <CardHeader className="justify-center">
          <FontAwesomeIcon icon={faUserLock} size="3x" />
        </CardHeader>
        <CardBody>
          <div className="backdrop-blur rounded p-1">
            <p className="text-tiny text-white/60 uppercase font-bold text-center">
              {t('components.CardSequenceBenefits.card4')}
            </p>
            <h4 className="text-white font-medium text-large text-center">
              {t('components.CardSequenceBenefits.card4Sub')}
            </h4>
          </div>
        </CardBody>
      </Card>
      <Card className="col-span-4 sm:col-span-2 md:col-span-1 bg-sky-500">
        <CardHeader className="justify-center">
          <FontAwesomeIcon icon={faMicrochip} size="3x" />
        </CardHeader>
        <CardBody>
          <div className="backdrop-blur rounded p-1">
            <p className="text-tiny text-white/60 uppercase font-bold text-center">
              {t('components.CardSequenceBenefits.card5')}
            </p>
            <h4 className="text-white font-medium text-large text-center">
              {t('components.CardSequenceBenefits.card5Sub')}
            </h4>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
