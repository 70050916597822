import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
  User,
  Chip,
  Skeleton,
} from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { GET_ME } from '../graphql/queries';
import { trackEvent, identifyUser } from '../utils/gt';

export const ProfileAvatar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { client, data: dataMe, loading: isLoadingMe } = useQuery(GET_ME);

  const logout = () => {
    client.clearStore();
    localStorage.removeItem('authToken');

    navigate('/');
  };

  const name = dataMe?.me?.name;
  const username = dataMe?.me?.username;
  const email = dataMe?.me?.email;

  useEffect(() => {
    username && identifyUser(username, name, email);
  }, [email, name, username]);

  if (isLoadingMe) {
    return (
      <Skeleton>
        <div className="w-full h-full">{t('standalone.loading')}</div>
      </Skeleton>
    );
  }

  return (
    <Dropdown placement="bottom-end" className="text-white/80">
      <DropdownTrigger className="cursor-pointer">
        <User
          as="button"
          avatarProps={{
            isBordered: true,
            showFallback: true,
            fallback: <FontAwesomeIcon icon={faUser} inverse size="lg" />,
          }}
          className="transition-transform"
          description={`@${username}`}
          name={name}
        />
      </DropdownTrigger>
      <DropdownMenu
        aria-label="Profile Actions"
        variant="flat"
        disabledKeys={['my_account']}
      >
        <DropdownSection aria-label="conected" showDivider>
          <DropdownItem key="profile" textValue="profile">
            <div className="flex items-center">
              <Chip
                size="sm"
                variant="bordered"
                color="success"
                className="text-white"
              >
                <p className="font-semibold">{`${email}`}</p>
              </Chip>
            </div>
          </DropdownItem>
        </DropdownSection>
        <DropdownSection aria-label="main" showDivider>
          <DropdownItem key="dashboard" onPress={() => navigate('/dashboard')}>
            {t('pages.Dashboard.dashboard')}
          </DropdownItem>
          <DropdownItem key="my_workouts" onPress={() => navigate('/workouts')}>
            {t('pages.Dashboard.myWorkouts')}
          </DropdownItem>
          <DropdownItem key="conect" onPress={() => navigate('/sync')}>
            {t('pages.Dashboard.sync')}
          </DropdownItem>
          <DropdownItem key="my_account">
            {t('pages.Dashboard.myAccount')}
          </DropdownItem>
        </DropdownSection>
        <DropdownItem
          key="logout"
          color="danger"
          className="text-danger"
          onPress={() => {
            trackEvent(
              'profile_avatar_logout',
              'Profile Avatar - Logout',
              'profile_avatar',
              true
            );

            logout();
          }}
        >
          {t('forms.buttons.logout')}
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
