import { Button } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

export const BackButton = ({ routeToGoBack }) => {
  const navigate = useNavigate();

  return (
    <Button isIconOnly onPress={() => navigate(routeToGoBack)}>
      <FontAwesomeIcon icon={faChevronLeft} inverse />
    </Button>
  );
};
