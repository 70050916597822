import { useCallback } from 'react';
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Image,
} from '@nextui-org/react';

export const ModalPhotoPicker = ({
  isOpen,
  onOpenChange,
  onClose,
  workoutImages,
  workoutData,
  setWorkoutData,
}) => {
  const renderImages = useCallback(
    () =>
      workoutImages.map((image, idx) => {
        return (
          <img
            key={`workout${idx}`}
            onClick={() => {
              setWorkoutData({
                ...workoutData,
                image: `workout${idx}`,
              });
              onClose();
            }}
            alt="Card background"
            className="w-full h-[90px] object-cover col-span-1 cursor-pointer"
            src={image}
          />
        );
      }),
    [onClose, setWorkoutData, workoutData, workoutImages]
  );

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top-center"
      backdrop="blur"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 text-white" />
            <ModalBody className="text-white">
              <div className="grid grid-cols-2 gap-2 sm:grid-cols-3">
                {renderImages()}
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
