import { useEffect } from 'react';
import {
  Navbar as NavNext,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Button,
  Image,
  Badge,
  useDisclosure,
  Card,
  CardBody,
} from '@nextui-org/react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import { trackEvent } from '../utils/gt';
import { GET_BANNER } from '../graphql/queries';
import meuTreinoLogoClean from '../assets/meuTreinoLogoClean.png';

import { ProfileAvatar } from './ProfileAvatar';
import { ModalLogin } from './ModalLogin';
import { ModalSignup } from './ModalSignup';

export const Navbar = ({ isLoggedInRoute }) => {
  const { t } = useTranslation();

  const { data: dataBanner } = useQuery(GET_BANNER);

  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onOpenChange: onLoginOpenChange,
    onClose: onLoginClose,
  } = useDisclosure();

  const {
    isOpen: isSignupOpen,
    onOpen: onSignupOpen,
    onOpenChange: onSignupOpenChange,
    onClose: onSignupClose,
  } = useDisclosure();

  const location = useLocation();

  const { pathname } = location;

  const banner = dataBanner?.banner?.data?.attributes?.banner;
  const isPt = t('lang') === 'pt';

  useEffect(() => {
    if (/^\/login/.test(pathname)) {
      onLoginOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoggedInRoute && banner?.shouldShowBanner === 'true' && (
        <Card className="bg-green-600" radius="none">
          <CardBody>
            <p>
              {isPt ? banner?.bannerTextPt : banner?.bannerTextEn}{' '}
              {isPt && banner?.bannerLinkTextPt ? (
                <a
                  href={banner?.bannerLinkPt}
                  target="_blank"
                  className="text-white"
                >
                  {banner?.bannerLinkTextPt}
                </a>
              ) : (
                banner?.bannerLinkTextEn && (
                  <a
                    href={banner?.bannerLinkEn}
                    target="_blank"
                    className="text-white"
                  >
                    {banner?.bannerLinkTextEn}
                  </a>
                )
              )}
            </p>
          </CardBody>
        </Card>
      )}
      <NavNext>
        <NavbarContent>
          <Badge
            content="beta"
            color="primary"
            size="sm"
            placement="top-right"
            showOutline={false}
            shape="circle"
          >
            <NavbarBrand>
              <Image
                src={meuTreinoLogoClean}
                alt="logo"
                className="rounded-full w-10"
              />

              <p className="font-bold text-inherit ml-2">{t('appName')}</p>
            </NavbarBrand>
          </Badge>
        </NavbarContent>

        <NavbarContent
          className="hidden sm:flex gap-4"
          justify="center"
        ></NavbarContent>
        <NavbarContent justify="end">
          {isLoggedInRoute ? (
            <>
              <NavbarItem>
                <ProfileAvatar />
              </NavbarItem>
            </>
          ) : (
            <>
              <NavbarItem className="hidden md:flex">
                <Button
                  color="primary"
                  variant="bordered"
                  onClick={() => {
                    trackEvent(
                      'navbar_login',
                      'Navbar - Click Login',
                      'navbar',
                      true
                    );

                    onLoginOpen();
                  }}
                >
                  {t('forms.buttons.login')}
                </Button>
              </NavbarItem>
              <NavbarItem className="hidden md:flex">
                <Button
                  color="primary"
                  onClick={() => {
                    trackEvent(
                      'navbar_signup',
                      'Navbar - Click Signup',
                      'navbar',
                      true
                    );

                    onSignupOpen();
                  }}
                >
                  {t('forms.buttons.signUp')}
                </Button>
              </NavbarItem>
              <NavbarItem className="flex md:hidden">
                <Button
                  color="primary"
                  onClick={() => {
                    trackEvent(
                      'navbar_access',
                      'Navbar - Click Access (mobile login)',
                      'navbar',
                      true
                    );

                    onSignupOpen();
                  }}
                >
                  {t('forms.buttons.access')}
                </Button>
              </NavbarItem>
            </>
          )}
        </NavbarContent>
        <ModalLogin
          isOpen={isLoginOpen}
          onOpenChange={onLoginOpenChange}
          onClose={onLoginClose}
        />
        <ModalSignup
          isOpen={isSignupOpen}
          onOpenChange={onSignupOpenChange}
          onClose={onSignupClose}
          openLogin={onLoginOpen}
        />
      </NavNext>
    </>
  );
};
